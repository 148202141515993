var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.form.building_level != undefined ? _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c('v-btn', _vm._b({
    attrs: {
      "disabled": _vm.loading || _vm.file_uploading
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _vm.$route.name === 'NewBuildingLevel' ? _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Új épület szint")]) : _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Épület szint szerkesztése")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "lg": 6
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Épület szint adatok")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.building_level.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.building_level, "name", $$v);
      },
      expression: "form.building_level.name"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Épület",
      "error-messages": _vm.errors.building_id,
      "items": _vm.buildings,
      "item-text": "label",
      "item-value": "value",
      "filled": ""
    },
    model: {
      value: _vm.form.building_level.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.form.building_level, "building_id", $$v);
      },
      expression: "form.building_level.building_id"
    }
  }), _vm.form.building_level.plan_file != '' ? _c('v-img', {
    attrs: {
      "src": _vm.form.plan_file_src,
      "height": "auto",
      "width": "200"
    }
  }) : _vm._e(), _vm.form.building_level.plan_file != '' ? _c('v-btn', {
    staticClass: "mb-8 mt-4",
    attrs: {
      "color": "secondary",
      "elevation": "0"
    },
    on: {
      "click": _vm.deleteUploadedFile
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-delete")]), _vm._v("Fájl törlése ")], 1) : _vm._e(), _c('v-progress-linear', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.file_uploading,
      expression: "file_uploading"
    }],
    staticClass: "mb-2",
    attrs: {
      "height": "8",
      "rounded": "",
      "value": _vm.file_uploading_percent
    }
  }), _c('v-file-input', {
    attrs: {
      "label": "Kép feltöltés",
      "show-size": "",
      "prepend-icon": "mdi-camera",
      "accept": "image/*",
      "filled": "",
      "error-messages": _vm.errors.uploaded_file,
      "disabled": _vm.file_uploading,
      "hide-details": "auto"
    },
    model: {
      value: _vm.form.uploaded_file,
      callback: function ($$v) {
        _vm.$set(_vm.form, "uploaded_file", $$v);
      },
      expression: "form.uploaded_file"
    }
  }), _c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "disabled": _vm.file_uploading || _vm.form.uploaded_file == '' || _vm.form.uploaded_file == null || _vm.errors.uploaded_file != undefined,
      "color": "secondary",
      "elevation": "0",
      "loading": _vm.file_uploading
    },
    on: {
      "click": _vm.uploadFile
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-upload")]), _vm._v("Fájl feltöltése ")], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }